<template>
    <centered-layout>
        <div class="text-center">
            <img
                :src="imageSrc"
                width="200px"
                alt="Snowflake"
            >
        </div>
        <h5 class="fw-light">
            It looks like your credit report is frozen.
        </h5>
        <p class="text-muted">
            In order to schedule with our notary, please unfreeze your Experian credit report and try again.
        </p>
        <div class="d-grid">
            <a
                class="btn btn-secondary btn-block mt-3"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.experian.com/ncaconline/removefreeze"
            > Unfreeze Report </a>
            <button
                class="btn btn-primary btn-block mt-3"
                @click="tryAgain"
            >
                Schedule Again
            </button>
        </div>
    </centered-layout>
</template>

<script>
    import { getNextRoute } from '@/flow/flowController'
    import CenteredLayout from '@/layouts/Centered'

    export default {
        components: {
            'centered-layout': CenteredLayout,
        },
        data() {
            return {
                imageSrc: require('../../assets/images/global/snowflake.svg'),
            }
        },
        mounted() {
            this.$logEvent('view_experian_frozen')
        },
        methods: {
            tryAgain: async function () {
                const nextRoute = getNextRoute(this.$router)
                this.$logEvent('click_button_experian_frozen_try_again', {
                    nextRoute,
                })
                await this.$router.push(nextRoute)
            },
        },
    }
</script>
